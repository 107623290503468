import React from "react";
import { Home } from "./Components/Home/Home";
import { Shell } from "./Components/Shell/Shell";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { getUTMs } from "./util/UTM";

function App() {
  getUTMs();

  return (
    <BrowserRouter>
      <Shell>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </Shell>
    </BrowserRouter>
  );
}

export default App;
