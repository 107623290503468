import React, { Component } from "react";
import styles from "./GenericButton.module.css";
import {NavLink } from "react-router-dom";

class GenericButton extends Component {

  render() {
    let button;
    const style = this.props.inverted ? styles.Inverted : styles.Button
    if(this.props.link){
      button = <a href={this.props.link} className={style} onClick={this.props.event}>{this.props.label}</a>;
    }else if( this.props.to){
      button = <NavLink to={this.props.to} className={style} onClick={this.props.event}>{this.props.label}</NavLink>;
    }else{
      button = <button  className={style} onClick={this.props.event}>{this.props.label}</button>;
    }

    return button
  }
}

export default GenericButton;
