import React from "react";
import styles from "./DoneBy.module.css";
import GenericButton from "../GenericButton/GenericButton";

export const DoneBy = ({ img, event }) => {
  const Title = () => (
    <div className={styles.Title}>¡Cambia tu sonrisa sin salir de casa!</div>
  );

  const Image = () => (
    <div className={styles.ImageContainer}>
      <img src={img} className={styles.Image} alt="Moonlight kit" />
    </div>
  );

  const showForm = () => event();

  const Content = () => (
    <div className={styles.InfoContainer}>
      <div className={styles.Text}>
        Agenda tu videollamada con uno de nuestros ortodoncistas y comienza tu
        asesoría dental totalmente gratis.
      </div>
      <div className={styles.ButtonContainer}>
        <GenericButton event={showForm} label="AGENDAR LLAMADA" />
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.MobileContainer}>
        <Image />
        <Title />
        <div className={styles.ContentMobile}>
          <Content />
        </div>
      </div>
      <div className={styles.DeskContainer}>
        <Image />
        <div className={styles.TIContainer}>
          <Title />
          <Content />
        </div>
      </div>
    </>
  );
};
