import React, { useState } from "react";
import styles from "./Home.module.css";
import { DoneBy } from "../DoneBy/DoneBy";
import Form from "../Form/Form";
import { apiBlackBox } from "../../Apis/BlackBox";

export const Home = (props) => {
  const [formIsVisible, setFormIsVisible] = useState(false);

  const zyman = window.webpSupported
    ? require("../../assets/images/webp/zyman.webp")
    : require("../../assets/images/png/zyman.png");

  const showForm = () => {
    setFormIsVisible(true);
    const pixel = document.createElement("img");
    pixel.setAttribute(
      "src",
      "https://www.facebook.com/tr?id=427318704548057&ev=ViewContent&noscript=1"
    );
    document.getElementById("header").appendChild(pixel);
    window.dataLayer.push({ event: "moonskit-agendar" });
  };

  const saveCRM = (data) => {
    const utcs = {
      utm_source: JSON.parse(window.localStorage.getItem("utm_source"))[0],
      utm_campaign: JSON.parse(window.localStorage.getItem("utm_campaign"))[0],
      utm_medium: JSON.parse(window.localStorage.getItem("utm_medium"))[0],
      creative: JSON.parse(window.localStorage.getItem("creative"))[0],
    };

    if (utcs.creative === null) {
      utcs.creative = "organico";
    }

    const pixelL = document.createElement("img");
    const pixelC = document.createElement("img");
    pixelL.setAttribute(
      "src",
      "https://www.facebook.com/tr?id=427318704548057&ev=Lead&noscript=1"
    );
    document.getElementById("header").appendChild(pixelL);

    pixelC.setAttribute(
      "src",
      "https://www.facebook.com/tr?id=427318704548057&ev=Contact&noscript=1"
    );
    document.getElementById("header").appendChild(pixelC);

    window.dataLayer.push({ event: "lead" });
    window.dataLayer.push({ event: "sendWA" });

    apiBlackBox.Patient(
      data.phone,
      data.email,
      data.name,
      utcs,
      "TestVideollamada3",
      "México"
    );
    window.open(
      "https://api.whatsapp.com/send?phone=5215584210728&text=¡Hola! Quiero agendar una videollamada con un ortodoncista para comenzar con mi asesoría dental"
    );
  };

  return (
    <div className={styles.Container} id="content">
      {!formIsVisible && (
        <div>
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: "none" }}
              alt="facebookPixel"
              src="https://www.facebook.com/tr?id=427318704548057&ev=PageView&noscript=1"
            />
          </noscript>
          <DoneBy img={zyman} event={showForm} />
        </div>
      )}
      {formIsVisible && (
        <Form button={"AGENDAR EN WHATSAPP"} formHandle={saveCRM} hasName />
      )}
    </div>
  );
};
