import React, { Component } from "react";
import styles from "./Form.module.css";
import imgWhats from "../../assets/images/svg/whatsapp-white.svg";

export default class Form extends Component {
  constructor() {
    super();
    this.state = {
      enable: false,
      phoneError: undefined,
      emailError: undefined,
      nameError: undefined,
    };
  }

  componentDidMount() {
    window.dataLayer.push({ event: "lead" });
  }

  nextHandler = () => {
    const phone = document.getElementById("phone").value;
    const email = document.getElementById("email").value;

    const emailError = !this.validateEmail(email);
    const phoneError = !this.validatePhone(phone);
    this.setState({ emailError, phoneError });

    if (this.props.hasName) {
      const name = document.getElementById("name").value;
      const nameError = !this.validateName(name);
      this.setState({ nameError });

      if (phoneError === false && emailError === false && nameError === false) {
        this.props.formHandle({ name, phone, email });
      }
    } else {
      if (!phoneError && !emailError) {
        //window.dataLayer.push({ event: "form_landing_test" });
        this.props.formHandle({ phone, email });
      }
    }
  };

  validateName(name) {
    if (name.charAt(0) !== " ") {
      const nameArray = name.split(" ");
      return nameArray.length > 1;
    } else {
      return false;
    }
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  }

  validatePhone(phone) {
    const re = /^\d{10}$/;
    return phone.match(re);
  }

  render() {
    const name = this.props.name !== undefined ? this.props.name : "";
    const email = this.props.email !== undefined ? this.props.email : "";
    const phone = this.props.phone !== undefined ? this.props.phone : "";
    const button =
      this.props.button !== undefined ? this.props.button : "SIGUIENTE";

    return (
      <div className={`${styles.containerFlex} ${styles.heightContainer}`}>
        <div className={styles.container}>
          <h1 className={styles.Title}>Llena nuestro formulario</h1>
          {this.props.hasName && (
            <div className={styles.form}>
              <label htmlFor="name" className={styles.inp}>
                <input
                  type="text"
                  id="name"
                  placeholder="&nbsp;"
                  defaultValue={name}
                />
                <span className={styles.label}>Tu nombre y apellido</span>
                <span className={styles.border}></span>
              </label>
            </div>
          )}
          {this.state.nameError && this.props.hasName && (
            <label className={styles.error}>Ingresa tu nombre y apellido</label>
          )}
          <div className={styles.form}>
            <label htmlFor="email" className={styles.inp}>
              <input id="email" defaultValue={email} placeholder="&nbsp;" />
              <span className={styles.label}>Tu correo</span>
              <span className={styles.border}></span>
            </label>
          </div>
          {this.state.emailError && (
            <label className={styles.error}>Ingresa un correo válido</label>
          )}
          <div className={styles.form}>
            <label htmlFor="phone" className={styles.inp}>
              <input
                id="phone"
                maxLength="10"
                defaultValue={phone}
                placeholder="&nbsp;"
              />
              <span className={styles.label}>Tu WhatsApp</span>
              <span className={styles.border}></span>
            </label>
          </div>
          {this.state.phoneError && (
            <label className={styles.error}>Ingresa un celular válido</label>
          )}
        </div>

        <div className={styles.nextContainer}>
          <button
            id="next"
            className={
              this.props.button !== undefined
                ? `${styles.buttonNext} ${styles.buttonNextCita}`
                : styles.buttonNext
            }
            onClick={this.nextHandler}
          >
            {button}
            <img src={imgWhats} alt="WhatsApp Moons" className={styles.Whats} />
          </button>
        </div>
      </div>
    );
  }
}
