import React from "react";
import styles from "./Footer.module.css";

export const Footer = (props) => {
  return (
    <div className={styles.Content}>
      <div>© Moons, 2020. Todos los derechos reservados.</div>
      <div>- Colima 220, Roma Norte, 06700, CDMX, México</div>
      <div>PERMISO COFEPRIS 203300201A0333</div>
      <div>
        Dr. Yair Zyman Fe, Cédula de licenciatura: 27966448 de la Universidad
        Tecnológica de México.
      </div>
      <div>
        Cédula de especialización: 8693572 de The University of Pittsburgh,
        Estados Unidos de América.
      </div>
    </div>
  );
};
